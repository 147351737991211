import styled from 'styled-components'
import tw from 'twin.macro'

export const Wrapper = styled.div``

export const Backdrop = tw.div` 
  fixed
  w-full
  h-full
  top-0
  left-0
  bg-gray-900
  opacity-[0.3]
  z-[500]
`

export const DrawerWrapper = tw.div`
  w-[357px]
  fixed
  top-[16px]
  bottom-[16px]
  right-[16px]
  outline[0]
  z-[700]
`

export const DrawerStyled = tw.div`
  h-full
  z-[100]
  bg-light-700
  relative
  flex
  flex-col
  p-6
  box-shadow[0px 4px 4px rgba(0, 0, 0, 0.25)]
  backdrop-filter[blur(16px)]
  border-radius[4px]
`

export const Header = tw.div`
  w-full
  flex
  items-center
  justify-between
  mb-[9px]
`

export const Title = tw.span`
  font-ringside-narrow
  font-weight[350]
  text-[16px]
  leading-[19px]
  text-light-200
`

export const Content = tw.div`
  overflow-x-hidden
  overflow-y-auto
  flex-1
  h-full
`

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import React, { useEffect, useRef, useCallback } from 'react'
import { useUpdateEffect } from 'react-use'
import { useRecoilValue } from 'recoil'

import useBlotterItemsList from 'hooks/useBlotterItemsList'
import useExecutionReport from 'hooks/useExecutionReport'
import { blotterColumnSortIcons } from 'lib/blotter-column-sort-icons'
import { generateColumnDefs } from 'lib/blotter-util'
import store from 'store'
import { ExecutionReport } from 'types/api/execution-report'
import { BlotterProps } from 'types/blotter-props'
import { OrderStatus } from 'types/order-status'

import { Wrapper } from './BlotterHistory.styles'

const columnDefs: ColDef<ExecutionReport>[] = generateColumnDefs('history')

const statuses: OrderStatus[] = ['Filled', 'Canceled', 'Rejected']

export const BlotterHistory: React.FC<BlotterProps> = ({
  'data-test': dataTest = 'blotter-history',
  exportBlotterActionCount,
}) => {
  const orders = useExecutionReport(statuses)

  const columnsToShow = useRecoilValue(store.blotters.columns('orders'))
  const ordersToShow = useBlotterItemsList(orders)

  const gridRef = useRef<AgGridReactType>()

  const AgGridColumnAPI = gridRef.current?.columnApi

  const exportData = useCallback(
    () =>
      gridRef.current?.api?.exportDataAsCsv({
        fileName: 'History',
      }),
    [],
  )

  useUpdateEffect(() => {
    exportData()
  }, [exportBlotterActionCount, exportData])

  useEffect(() => {
    if (AgGridColumnAPI && columnsToShow) {
      AgGridColumnAPI.moveColumns(
        columnsToShow.map((column) => column.id),
        0,
      )

      columnsToShow.forEach((column) =>
        AgGridColumnAPI.applyColumnState({
          state: [{ colId: column.id, hide: !column.show }],
        }),
      )
    }
  }, [AgGridColumnAPI, columnsToShow])

  return (
    <Wrapper
      className="ag-theme-alpine ag-theme-scrypt-blotter"
      data-test={`${dataTest}.container`}
    >
      <AgGridReact
        rowData={ordersToShow}
        columnDefs={columnDefs}
        rowHeight={32}
        headerHeight={24}
        icons={blotterColumnSortIcons}
        ref={gridRef}
      />
    </Wrapper>
  )
}

import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from './Checkbox.styles'

export interface CheckboxProps {
  'data-test': string
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Checkbox: React.FC<CheckboxProps> = ({
  'data-test': dataTest = 'checkbox',
  checked,
  onChange,
}) => (
  <CheckboxContainer data-test={`${dataTest}.container`}>
    <HiddenCheckbox
      data-test={`${dataTest}.element`}
      checked={checked}
      onChange={onChange}
    />
    <StyledCheckbox checked={checked}>
      <Icon data-test={`${dataTest}.icon`} viewBox="0 0 15 13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7068 0.29254C15.0975 0.682869 15.0978 1.31603 14.7075 1.70675L4.70746 11.7168C4.51996 11.9044 4.26555 12.0099 4.00025 12.01C3.73495 12.0101 3.48049 11.9047 3.29289 11.7171L0.292893 8.71711C-0.0976311 8.32658 -0.0976311 7.69342 0.292893 7.30289C0.683417 6.91237 1.31658 6.91237 1.70711 7.30289L3.99965 9.59543L13.2925 0.293247C13.6829 -0.0974727 14.316 -0.0977892 14.7068 0.29254Z"
          fill="white"
        />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

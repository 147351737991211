import styled from 'styled-components'
import tw from 'twin.macro'

import CheckCircle from 'assets/icons/check-circle.svg'
import DragHandle from 'assets/icons/drag-handle-dots.svg'
import Search from 'assets/icons/search.svg'

export const InputWrapper = tw.div`relative mb-2`

export const SearchIcon = tw(
  Search,
)`w-4 h-full absolute right-[8px] top-0 bottom-0 fill-light-400`

export const DragHandleIcon = tw(DragHandle)`w-[16px] fill-light-500`

export const CheckCircleIcon = tw(CheckCircle)`w-[16px] mr-2 fill-light-500`

export const ItemWrapper = styled.div<{ odd?: boolean }>`
  ${tw`h-[32px] flex justify-between items-center p-2`}

  background-color: ${(props) =>
    props.odd ? 'rgba(255, 255, 255, 0.04)' : 'unset'};
`

export const ItemColumnName = tw.span`ml-2 font-medium text-[14px] leading-[19px] text-light-200`

export const ButtonWrapper = tw.div`mt-2`

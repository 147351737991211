import { useCallback, useMemo, useState } from 'react'
import { useCounter } from 'react-use'

import Button from 'components/Button'
import Drawer from 'components/Drawer'
import IconButton from 'components/IconButton'
import { BlotterColumnsCategory } from 'types/blotter-columns-category'
import { BlotterType } from 'types/blotter-type'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ColumnsIcon,
  ExternalLinkIcon,
  Header,
  Toolbar,
  Wrapper,
} from './Blotter.styles'
import BlotterHistory from './BlotterHistory'
import BlotterOpenOrders from './BlotterOpenOrders'
import BlotterOrders from './BlotterOrders'
import BlotterTab from './BlotterTab'
import BlotterTrades from './BlotterTrades'
import { OrdersColumnsEditor } from './OrdersColumnsEditor'
import { TradesColumnsEditor } from './TradesColumnsEditor'

export interface BlotterProps {
  'data-test': string
}

export const Blotter: React.FC<BlotterProps> = ({
  'data-test': dataTest = 'blotter',
}) => {
  const [blotterType, setBlotterType] = useState<BlotterType>('open-orders')
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [showBlotter, setShowBlotter] = useState<boolean>(true)

  const [exportBlotterActionCount, { inc: exportBlotter }] = useCounter()

  const handleExport = useCallback(() => exportBlotter(), [exportBlotter])
  const handleEditColumns = useCallback(() => {
    setShowDrawer(true)
    setShowBlotter(true)
  }, [])

  const blotterColumnsCategory = useMemo<BlotterColumnsCategory>(
    () =>
      ['open-orders', 'orders'].includes(blotterType) ? 'orders' : 'trades',
    [blotterType],
  )

  const toggleBlotter = useCallback(
    () => setShowBlotter(!showBlotter),
    [showBlotter],
  )

  const handleBlotterTabChange = useCallback((type: BlotterType) => {
    setBlotterType(type)
    setShowBlotter(true)
  }, [])

  return (
    <>
      <Wrapper data-test={`${dataTest}.container`}>
        <Header>
          <BlotterTab
            data-test={`${dataTest}.tab`}
            selectedType={blotterType}
            onChange={handleBlotterTabChange}
          />
          <Toolbar>
            <Button onClick={handleExport} disabled={!showBlotter}>
              <ExternalLinkIcon />
              <span>Export</span>
            </Button>
            <Button
              data-test={`${dataTest}.drawer-toggle`}
              onClick={handleEditColumns}
            >
              <ColumnsIcon />
              <span>Edit Columns</span>
            </Button>
            <IconButton
              data-test={`${dataTest}.blotter-toggle`}
              onClick={toggleBlotter}
            >
              {showBlotter ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </IconButton>
          </Toolbar>
        </Header>
        {showBlotter ? (
          <div data-test={`${dataTest}.blotter-container`}>
            {blotterType === 'open-orders' ? (
              <BlotterOpenOrders
                data-test={`${dataTest}.open-order`}
                exportBlotterActionCount={exportBlotterActionCount}
              />
            ) : null}
            {blotterType === 'orders' ? (
              <BlotterOrders
                data-test={`${dataTest}.order`}
                exportBlotterActionCount={exportBlotterActionCount}
              />
            ) : null}
            {blotterType === 'trades' ? (
              <BlotterTrades
                data-test={`${dataTest}.trade`}
                exportBlotterActionCount={exportBlotterActionCount}
              />
            ) : null}
            {blotterType === 'history' ? (
              <BlotterHistory
                data-test={`${dataTest}.history`}
                exportBlotterActionCount={exportBlotterActionCount}
              />
            ) : null}
          </div>
        ) : null}
      </Wrapper>

      <Drawer
        data-test={`${dataTest}.drawer`}
        isShown={showDrawer}
        title="Edit Columns"
        onHide={() => setShowDrawer(false)}
      >
        {'orders' === blotterColumnsCategory ? (
          <OrdersColumnsEditor data-test={`${dataTest}.column-editor`} />
        ) : (
          <TradesColumnsEditor data-test={`${dataTest}.trade-editor`} />
        )}
      </Drawer>
    </>
  )
}

import { useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { makeMonthAgo } from 'lib/app-util'
import {
  ExecutionReport,
  ExecutionReportParameters,
} from 'types/api/execution-report'
import { OrderStatus } from 'types/order-status'

const getLastMonthOrderStreamParameters = (
  Statuses?: OrderStatus[],
): ExecutionReportParameters => ({
  name: 'ExecutionReport',
  sort_by: '-Timestamp',
  Statuses,
  StartDate: makeMonthAgo().toISOString(),
})

export const useExecutionReport = (
  Statuses?: OrderStatus[],
): ExecutionReport[] | null => {
  const socket = useSocket()

  const executionReportParameters: ExecutionReportParameters = useMemo(
    () => getLastMonthOrderStreamParameters(Statuses),
    [Statuses],
  )

  const orders = useSubscription<ExecutionReport>(
    socket,
    executionReportParameters,
  )

  return orders
}

import { omit } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { useLatest } from 'react-use'
import 'twin.macro'

import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Input from 'components/Input'
import { Column } from 'types/column'

import {
  ButtonWrapper,
  CheckCircleIcon,
  DragHandleIcon,
  InputWrapper,
  ItemColumnName,
  ItemWrapper,
  SearchIcon,
} from './ColumnsEditor.styles'

export interface ColumnsEditorProps {
  'data-test': string
  columns: Column[]
  onChange: (columns: Column[]) => void
}

const bilayerClone = (objArray) =>
  objArray ? [...objArray.map((el) => ({ ...el }))] : null

const thirdPartyLibraryAddedFields = ['chosen', 'selected']
const bilayerCloneStrip3rdPartyAddedFields = (objArray) =>
  objArray
    ? [...objArray.map((el) => ({ ...omit(el, thirdPartyLibraryAddedFields) }))]
    : null

export const ColumnsEditor: React.FC<ColumnsEditorProps> = ({
  'data-test': dataTest = 'column-editor',
  columns,
  onChange,
}) => {
  const [items, _setItems] = useState<Column[]>(() => bilayerClone(columns))
  const latestItems = useLatest(items)
  const setItems = useCallback(
    (columns) => _setItems(bilayerClone(columns)),
    [_setItems],
  )

  const [searchText, setSearchText] = useState<string>('')

  const filteredItems = useMemo(() => {
    if (!searchText || searchText.length === 0) return []
    return items.filter((item) =>
      item.id.toLowerCase().includes(searchText.toLowerCase()),
    )
  }, [items, searchText])

  const handleCheckboxOnChange = (checked: boolean, columnToChange: Column) => {
    const newColums = items ? [...items] : []
    const columnIndex = items.findIndex(
      (column) => column.id === columnToChange.id,
    )
    if (columnIndex !== -1) {
      newColums[columnIndex].show = checked
      setItems(newColums)
    }
  }

  const handleOnSave = useCallback(() => {
    onChange(bilayerCloneStrip3rdPartyAddedFields(latestItems.current))
  }, [onChange, latestItems])

  const handleSearchInputOnChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => setSearchText(event.target.value)

  return (
    <div data-test={`${dataTest}.container`}>
      <InputWrapper>
        <Input
          data-test={`${dataTest}.search`}
          type="text"
          tw="w-full"
          placeholder="Search"
          value={searchText}
          onChange={handleSearchInputOnChange}
        />
        <SearchIcon />
      </InputWrapper>
      {filteredItems.length === 0 && searchText.trim().length > 0 ? (
        <ItemWrapper>
          <ItemColumnName data-test={`${dataTest}.no-result`}>
            No column found
          </ItemColumnName>
        </ItemWrapper>
      ) : null}

      {filteredItems.length > 0 ? (
        <>
          {filteredItems.map((column, index) => (
            <ItemWrapper key={column.id} odd={index % 2 !== 0}>
              <label>
                <Checkbox
                  data-test={`${dataTest}.checkbox-filter`}
                  data-test-arg={column.id}
                  checked={column.show}
                  onChange={(event) =>
                    handleCheckboxOnChange(event.target.checked, column)
                  }
                />
                <ItemColumnName data-test={`${dataTest}.checkbox-filter-name`}>
                  {column.id}
                </ItemColumnName>
              </label>
            </ItemWrapper>
          ))}
        </>
      ) : null}

      {filteredItems.length === 0 && searchText.trim().length === 0 ? (
        <ReactSortable list={items} setList={setItems}>
          {items.map((column, index) => (
            <ItemWrapper key={column.id} odd={index % 2 !== 0}>
              <label>
                <Checkbox
                  data-test={`${dataTest}.checkbox-search`}
                  data-test-arg={column.id}
                  checked={column.show}
                  onChange={(event) =>
                    handleCheckboxOnChange(event.target.checked, column)
                  }
                />
                <ItemColumnName data-test={`${dataTest}.checkbox-search-name`}>
                  {column.id}
                </ItemColumnName>
              </label>
              <DragHandleIcon />
            </ItemWrapper>
          ))}
        </ReactSortable>
      ) : null}
      <ButtonWrapper>
        <Button data-test={`${dataTest}.submit`} onClick={handleOnSave}>
          <CheckCircleIcon />
          Save
        </Button>
      </ButtonWrapper>
    </div>
  )
}

import { isNil } from 'lodash'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { ExecutionReport } from 'types/api/execution-report'
import { Order } from 'types/api/order'
import { Trade } from 'types/api/trade'

export type BlotterList = Order[] | Trade[] | ExecutionReport[]

// this returns a the items that will show in the blotter.
export const useBlotterItemsList = (list: BlotterList) => {
  const [listToShow, setListToShow] = useState<BlotterList>([])

  useUpdateEffect(() => {
    // if list to show is empty set the whole data from API to the list
    if (!isNil(list) && list.length > 0) {
      if (isNil(listToShow) || listToShow.length === 0) {
        setListToShow(list)
      } else {
        // take the new data and add it to the exitisting list, removing the old status of the same order
        const newOrdersData = [...listToShow]
        for (const newOrder of list) {
          const oldOrderIndex = newOrdersData.findIndex(
            (order) => order.OrderID === newOrder.OrderID,
          )
          if (oldOrderIndex !== -1) {
            newOrdersData[oldOrderIndex] = newOrder
          } else {
            newOrdersData.push(newOrder)
          }
        }
        setListToShow(newOrdersData)
      }
    }
  }, [list])

  return listToShow
}

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { format } from 'date-fns'
import 'twin.macro'
import { isNil } from 'lodash'

import CloseIcon from 'assets/icons/close.svg'
import CurrencyPairHeader from 'components/CurrencyPairHeader'
import { ProgressBar } from 'components/RFQTile/ProgressBar/ProgressBar'
import { useCancelOrder } from 'hooks/trade/useCancelOrder'
import { dateComparator } from 'lib/date-util'
import { maybeNumber } from 'lib/decimal-util'
import { formatDecimal, decimalComparator } from 'lib/decimal-util'
import { stringComparator } from 'lib/set-util'
import { ExecutionReport } from 'types/api/execution-report'
import { Order } from 'types/api/order'
import { Trade } from 'types/api/trade'
import { CellRendererData } from 'types/cell-renderer-data'

import {
  blotterOrdersColumnsNames,
  blotterTradesColumnsNames,
} from './blotter-column-names'

const CANCELABLE_ORDER_TYPES = ['New', 'PartiallyFilled', 'PendingNew']

const CancelRenderer = (params: ICellRendererParams) => {
  const { OrdStatus, OrderID, ClOrdID } = params.data as Order

  const cancel = useCancelOrder(OrderID, ClOrdID)
  const canceled = CANCELABLE_ORDER_TYPES.indexOf(OrdStatus) < 0
  return (
    <div>
      {!canceled && (
        <button onClick={cancel}>
          <CloseIcon tw="fill-light-200" />
        </button>
      )}
    </div>
  )
}

const ProgressRenderer = (params: ICellRendererParams) => {
  const { CumQty, OrderQty } = params.data as Order
  const maybeCumQty = maybeNumber(CumQty)
  const maybeOrderQty = maybeNumber(OrderQty)
  if (!maybeCumQty || !maybeOrderQty) return <div tw="text-light-600">N/A</div>
  const ratio = maybeCumQty / maybeOrderQty
  return (
    <div tw="flex gap-3 justify-center items-center">
      <ProgressBar
        percentage={ratio}
        variant="Blotter"
        opacity
        data-test={'progress-bar'}
      />
      <div>{Math.ceil(ratio * 100)}%</div>
    </div>
  )
}

/**
 * It defines the properties for every column for the "open-orders", "orders",
 * and "history" blotter using the AG Grid API.
 *
 * If you want to add a column definition just add an object at the end of this array.
 * If you want to define or change the columns order you have to see the `OrdersBlotterColumns` object in 'lib/blotter-columns'
 */
export const OrderAndHistoryColumnDefs: ColDef<Order | ExecutionReport>[] = [
  {
    field: blotterOrdersColumnsNames.progress,
    cellRenderer: ProgressRenderer,
  },
  {
    field: blotterOrdersColumnsNames.date,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      format(new Date(record.data.SubmitTime), 'yyyy-MM-dd HH:mm:ss'),
    sortable: true,
    sort: 'desc',
    comparator: (valueA, valueB, nodeA, nodeB) =>
      dateComparator(nodeA.data.SubmitTime, nodeB.data.SubmitTime),
  },
  {
    field: blotterOrdersColumnsNames.status,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.OrdStatus,
  },
  {
    field: blotterOrdersColumnsNames.orderType,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.OrdType,
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(nodeA.data.OrdType, nodeB.data.OrdType),
  },
  {
    field: blotterOrdersColumnsNames.symbol,
    cellRenderer: (record: CellRendererData<Order | ExecutionReport>) => {
      if (!isNil(record.data.Symbol)) {
        const [, baseSymbol, quotedSymbol] =
          record.data.Symbol.match(/(.*)[-\/](.*)/i)
        return (
          <CurrencyPairHeader
            data-test="blotterOrdersColumns.symbol.currencyPairHeader"
            baseCurrency={{ Symbol: baseSymbol, Description: '' }}
            quotedCurrency={{ Symbol: quotedSymbol, Description: '' }}
            readonly
          />
        )
      }
    },
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.Symbol,
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) => {
      const [, baseSymbolA, quotedSymbolA] =
        nodeA.data.Symbol.match(/(.*)[-\/](.*)/i)

      const [, baseSymbolB, quotedSymbolB] =
        nodeB.data.Symbol.match(/(.*)[-\/](.*)/i)

      return stringComparator(
        `${baseSymbolA}${quotedSymbolA}`,
        `${baseSymbolB}${quotedSymbolB}`,
      )
    },
  },
  {
    field: blotterOrdersColumnsNames.side,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.Side?.toUpperCase(),
    width: 100,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(nodeA.data.Side, nodeB.data.Side),
  },
  {
    field: blotterOrdersColumnsNames.currency,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.Currency,
    width: 100,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(nodeA.data.Currency, nodeB.data.Currency),
  },
  {
    field: blotterOrdersColumnsNames.amountCurrency,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.AmountCurrency,
    width: 150,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(nodeA.data.AmountCurrency, nodeB.data.AmountCurrency),
  },
  {
    field: blotterOrdersColumnsNames.orderQTY,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      formatDecimal(record.data.OrderQty),
    width: 120,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.OrderQty, nodeB.data.OrderQty),
  },
  {
    field: blotterOrdersColumnsNames.price,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      formatDecimal(record.data.Price),
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.Price, nodeB.data.Price),
  },
  {
    field: blotterOrdersColumnsNames.filledQTY,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      formatDecimal(record.data.CumQty),
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.CumQty, nodeB.data.CumQty),
  },
  {
    field: blotterOrdersColumnsNames.averagePrice,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      formatDecimal(record.data.AvgPx),
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.AvgPx, nodeB.data.AvgPx),
  },
  {
    field: blotterOrdersColumnsNames.totalAmount,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      formatDecimal(record.data.CumAmt),
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.CumAmt, nodeB.data.CumAmt),
  },
  {
    field: blotterOrdersColumnsNames.orderID,
    valueGetter: (record: CellRendererData<Order | ExecutionReport>) =>
      record.data.ClOrdID,
    width: 300,
  },
  {
    field: blotterOrdersColumnsNames.action,
    cellRenderer: CancelRenderer,
  },
]

/**
 * It defines the properties for every column for the "trades" blotter using the AG Grid API.
 *
 * If you want to add a column definition just add an object at the end of this array.
 * If you want to define or change the columns order you have to see the `TradesBlotterColumns` object in 'lib/blotter-columns'
 */
export const TradesColumnDefs: ColDef<Trade>[] = [
  {
    field: blotterTradesColumnsNames.date,
    valueGetter: (record: CellRendererData<Trade>) =>
      format(new Date(record.data.TransactTime), 'yyyy-MM-dd HH:mm:ss'),
    sortable: true,
    sort: 'desc',
    comparator: (valueA, valueB, nodeA, nodeB) =>
      dateComparator(nodeA.data.TransactTime, nodeB.data.TransactTime),
  },
  {
    field: blotterTradesColumnsNames.status,
    valueGetter: (record: CellRendererData<Trade>) => record.data.TradeStatus,
  },
  {
    field: blotterTradesColumnsNames.symbol,
    cellRenderer: (record: CellRendererData<Trade>) => (
      <CurrencyPairHeader
        data-test="blotterTradesColumns.symbol.currencyPairHeader"
        baseCurrency={{ Symbol: record.data.Currency, Description: '' }}
        quotedCurrency={{
          Symbol: record.data.AmountCurrency,
          Description: '',
        }}
        readonly
      />
    ),
    valueGetter: (record: CellRendererData<Trade>) =>
      record.data.Currency + '-' + record.data.AmountCurrency,
    width: 130,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(
        `${nodeA.data.Currency}${nodeA.data.AmountCurrency}`,
        `${nodeB.data.Currency}${nodeB.data.AmountCurrency}`,
      ),
  },
  {
    field: blotterTradesColumnsNames.side,
    valueGetter: (record: CellRendererData<Trade>) =>
      record.data.Side.toUpperCase(),
    width: 100,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      stringComparator(nodeA.data.Side, nodeB.data.Side),
  },
  {
    field: blotterTradesColumnsNames.quantity,
    valueGetter: (record: CellRendererData<Trade>) =>
      formatDecimal(record.data.Quantity),
    width: 120,
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.Quantity, nodeB.data.Quantity),
  },
  {
    field: blotterTradesColumnsNames.amount,
    valueGetter: (record: CellRendererData<Trade>) =>
      formatDecimal(record.data.Amount),
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.Amount, nodeB.data.Amount),
  },
  {
    field: blotterTradesColumnsNames.price,
    valueGetter: (record: CellRendererData<Trade>) =>
      formatDecimal(record.data.Price),
    sortable: true,
    comparator: (valueA, valueB, nodeA, nodeB) =>
      decimalComparator(nodeA.data.Price, nodeB.data.Price),
  },
  {
    field: blotterTradesColumnsNames.tradeID,
    valueGetter: (record: CellRendererData<Trade>) => record.data.TradeID,
    width: 300,
  },
]

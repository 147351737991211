import React from 'react'
import { useRecoilState } from 'recoil'

import store from 'store'

import ColumnsEditor from './ColumnsEditor'

export interface TradesColumnsEditorProps {
  'data-test': string
}

export const TradesColumnsEditor: React.FC<TradesColumnsEditorProps> = ({
  'data-test': dataTest = 'trade-column-editor',
}) => {
  const [columns, setColumns] = useRecoilState(store.blotters.columns('trades'))

  return (
    <ColumnsEditor
      data-test={`${dataTest}.column-editor`}
      columns={columns}
      onChange={setColumns}
    />
  )
}

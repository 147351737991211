import { useCallback } from 'react'

import { useIsConnected } from 'hooks/useIsConnected'
import { useSocket } from 'hooks/useSocket'
import { createReqId } from 'lib/app-util'

export const useCancelOrder = (orderId: string, clientOrderId: string) => {
  const socket = useSocket()
  const isConnected = useIsConnected()
  const cancel = useCallback(() => {
    if (isConnected) {
      socket.send(
        JSON.stringify({
          reqid: createReqId(),
          type: 'OrderCancelRequest',
          data: [
            {
              ClOrdID: clientOrderId,
              OrderID: orderId,
              TransactTime: new Date().toISOString(),
            },
          ],
        }),
      )
    }
  }, [isConnected, socket, orderId, clientOrderId])

  return cancel
}

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import { useCallback, useEffect, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { useRecoilValue } from 'recoil'

import useBlotterItemsList from 'hooks/useBlotterItemsList'
import useTrade from 'hooks/useTrade'
import { blotterColumnSortIcons } from 'lib/blotter-column-sort-icons'
import { generateColumnDefs } from 'lib/blotter-util'
import store from 'store'
import { Trade } from 'types/api/trade'
import { BlotterProps } from 'types/blotter-props'

import { Wrapper } from './BlotterTrades.styles'

const columnDefs: ColDef<Trade>[] = generateColumnDefs('trades')

export const BlotterTrades: React.FC<BlotterProps> = ({
  'data-test': dataTest = 'blotter-trade',
  exportBlotterActionCount,
}) => {
  const trades = useTrade()

  const columnsToShow = useRecoilValue(store.blotters.columns('trades'))
  const tradesToShow = useBlotterItemsList(trades)

  const gridRef = useRef<AgGridReactType>()

  const AgGridColumnAPI = gridRef.current?.columnApi

  const exportData = useCallback(
    () =>
      gridRef.current?.api?.exportDataAsCsv({
        fileName: 'Trades',
      }),
    [],
  )

  useUpdateEffect(() => {
    exportData()
  }, [exportBlotterActionCount, exportData])

  useEffect(() => {
    if (AgGridColumnAPI && columnsToShow) {
      AgGridColumnAPI.moveColumns(
        columnsToShow.map((column) => column.id),
        0,
      )

      columnsToShow.forEach((column) =>
        AgGridColumnAPI.applyColumnState({
          state: [{ colId: column.id, hide: !column.show }],
        }),
      )
    }
  }, [AgGridColumnAPI, columnsToShow])

  return (
    <Wrapper
      data-test={`${dataTest}.container`}
      className="ag-theme-alpine ag-theme-scrypt-blotter"
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={tradesToShow}
        rowHeight={32}
        headerHeight={24}
        icons={blotterColumnSortIcons}
        ref={gridRef}
      />
    </Wrapper>
  )
}

import { ReactNode, useCallback } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import Close from 'assets/icons/close.svg'
import IconButton from 'components/IconButton'

import {
  Wrapper,
  Backdrop,
  DrawerWrapper,
  DrawerStyled,
  Header,
  Title,
  Content,
} from './Drawer.styles'

export interface DrawerProps {
  'data-test': string
  children?: ReactNode
  isShown: boolean
  title?: string
  onHide?: () => void
}

export const Drawer: React.FC<DrawerProps> = ({
  'data-test': dataTest = 'drawer',
  children,
  isShown,
  title,
  onHide,
}) => {
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === 'Escape' && isShown) {
        onHide && onHide()
      }
      event.stopPropagation()
    },
    [onHide, isShown],
  )

  isShown
    ? (document.body.style.overflow = 'hidden')
    : (document.body.style.overflow = 'unset')

  const drawer = (
    <Wrapper data-test={`${dataTest}.container`} onKeyDown={onKeyDown}>
      <Backdrop
        data-test={`${dataTest}.backdrop`}
        aria-hidden
        onClick={onHide}
      />
      <FocusLock>
        <DrawerWrapper
          aria-modal
          aria-labelledby={title}
          tabIndex={-1}
          role="dialog"
        >
          <DrawerStyled>
            <Header>
              <Title data-test={`${dataTest}.title`}>{title}</Title>
              <IconButton data-test={`${dataTest}.hide`} onClick={onHide}>
                <Close />
              </IconButton>
            </Header>
            <Content data-test={`${dataTest}.children`}>{children}</Content>
          </DrawerStyled>
        </DrawerWrapper>
      </FocusLock>
    </Wrapper>
  )

  return isShown ? ReactDOM.createPortal(drawer, document.body) : null
}

import tw from 'twin.macro'

import ChevronDown from 'assets/icons/chevron-down.svg'
import Columns from 'assets/icons/columns.svg'
import ExternalLink from 'assets/icons/external-link.svg'

export const Wrapper = tw.div`flex flex-col gap-[20px]`
export const Header = tw.div`flex justify-between items-center`
export const Toolbar = tw.div`flex items-center`
export const ColumnsIcon = tw(Columns)`w-[18px] mt-[-3px] mr-1.5 fill-light-600`
export const ExternalLinkIcon = tw(
  ExternalLink,
)`w-[18px] mt-[-3px] mr-1.5 fill-light-600`
export const ChevronDownIcon = tw(ChevronDown)``
export const ChevronUpIcon = tw(ChevronDown)`rotate-180`

import TabButton from 'components/TabButton'
import { blotterTypeLabels } from 'lib/blotter-type-labels'
import { BlotterType } from 'types/blotter-type'

import { Wrapper } from './BlotterTab.styles'

export interface BlotterTabProps {
  'data-test': string
  selectedType: BlotterType
  onChange: (type: BlotterType) => void
}

export const BlotterTab: React.FC<BlotterTabProps> = ({
  'data-test': dataTest = 'blotter-tab',
  selectedType,
  onChange,
}) => (
  <Wrapper data-test={`${dataTest}.container`}>
    {Object.entries(blotterTypeLabels).map(
      ([key, label]: [BlotterType, string]) => (
        <TabButton
          key={key}
          data-test={`${dataTest}.tab-button`}
          data-test-arg={key}
          selected={selectedType === key}
          onClick={() => onChange(key)}
        >
          {label}
        </TabButton>
      ),
    )}
  </Wrapper>
)

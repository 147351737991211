import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export interface TabButtonProps {
  selected?: boolean
}

export const TabButton = styled.button<TabButtonProps>`
  ${tw`
    min-h-[40px]
    py-2
    px-4
    border-radius[4px]
    border
    border-color[transparent]
    font-ringside-narrow
    font-weight[350]
    text-[14px]
    leading-[16px]
    text-light-300
    flex
    items-center
  `}

  ${({ selected }) =>
    selected
      ? tw`bg-[rgba(255, 255, 255, 0.05)] text-brand-orange`
      : css`
          ${tw`hover:bg-[rgba(255, 255, 255, 0.05)] hover:text-white`}
        `}
`

import { ColDef } from 'ag-grid-community'

import { ExecutionReport } from 'types/api/execution-report'
import { Order } from 'types/api/order'
import { Trade } from 'types/api/trade'
import { BlotterType } from 'types/blotter-type'

import {
  TradesColumnDefs,
  OrderAndHistoryColumnDefs,
} from './blotter-column-defs'
import { TradesBlotterColumns, OrdersBlotterColumns } from './blotter-columns'

export const generateColumnDefs = (
  blotterTyle: BlotterType,
): ColDef<Order | ExecutionReport | Trade>[] => {
  if (blotterTyle === 'trades') {
    return TradesBlotterColumns.map((tradeColumn) =>
      TradesColumnDefs.find((colDef) => colDef.field === tradeColumn.id),
    )
  } else {
    return OrdersBlotterColumns.map((orderColumn) =>
      OrderAndHistoryColumnDefs.find(
        (colDef) => colDef.field === orderColumn.id,
      ),
    )
  }
}

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { AgGridReact as AgGridReactType } from 'ag-grid-react/lib/agGridReact'
import React, { useCallback, useEffect, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import { useRecoilValue } from 'recoil'

import useBlotterItemsList from 'hooks/useBlotterItemsList'
import useOrder from 'hooks/useOrder'
import { blotterColumnSortIcons } from 'lib/blotter-column-sort-icons'
import { generateColumnDefs } from 'lib/blotter-util'
import store from 'store'
import { Order } from 'types/api/order'
import { BlotterProps } from 'types/blotter-props'
import { OrderStatus } from 'types/order-status'

import { Wrapper } from './BlotterOpenOrders.styles'

const columnDefs: ColDef<Order>[] = generateColumnDefs('open-orders')

const statuses: OrderStatus[] = [
  'New',
  'PartiallyFilled',
  'PendingNew',
  'PendingCancel',
  'PendingReplace',
]

export const BlotterOpenOrders: React.FC<BlotterProps> = ({
  'data-test': dataTest = 'blotter-open-order',
  exportBlotterActionCount,
}) => {
  const orders = useOrder(statuses)

  const ordersToShow = useBlotterItemsList(orders)
  const columnsToShow = useRecoilValue(store.blotters.columns('orders'))

  const gridRef = useRef<AgGridReactType>()

  const AgGridColumnAPI = gridRef.current?.columnApi

  const exportData = useCallback(
    () =>
      gridRef.current?.api?.exportDataAsCsv({
        fileName: 'Open Orders',
      }),
    [],
  )

  useUpdateEffect(() => {
    exportData()
  }, [exportBlotterActionCount, exportData])

  useEffect(() => {
    if (AgGridColumnAPI && columnsToShow) {
      AgGridColumnAPI.moveColumns(
        columnsToShow.map((column) => column.id),
        0,
      )

      columnsToShow.forEach((column) =>
        AgGridColumnAPI.applyColumnState({
          state: [{ colId: column.id, hide: !column.show }],
        }),
      )
    }
  }, [AgGridColumnAPI, columnsToShow])

  return (
    <Wrapper
      data-test={`${dataTest}.container`}
      className="ag-theme-alpine ag-theme-scrypt-blotter"
    >
      <AgGridReact
        rowData={ordersToShow}
        columnDefs={columnDefs}
        rowHeight={32}
        headerHeight={24}
        icons={blotterColumnSortIcons}
        ref={gridRef}
      />
    </Wrapper>
  )
}

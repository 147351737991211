import { useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { makeMonthAgo } from 'lib/app-util'
import { Trade, TradeParameters } from 'types/api/trade'

const getLastMonthTradeStreamParameters = (): TradeParameters => ({
  name: 'Trade',
  sort_by: '-Timestamp',
  StartDate: makeMonthAgo().toISOString(),
})

export const useTrade = (): Trade[] | null => {
  const socket = useSocket()

  // Have to set some start date to get any orders back.
  const tradeParameters: TradeParameters = useMemo(
    () => getLastMonthTradeStreamParameters(),
    [],
  )

  const trades = useSubscription<Trade>(socket, tradeParameters)

  return trades
}

import { useMemo } from 'react'

import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { makeMonthAgo } from 'lib/app-util'
import { Order, OrderParameters } from 'types/api/order'
import { OrderStatus } from 'types/order-status'

const getLastMonthOrderStreamParameters = (
  Statuses?: OrderStatus[],
): OrderParameters => ({
  name: 'Order',
  sort_by: '-Timestamp',
  Statuses,
  StartDate: makeMonthAgo().toISOString(),
})

export const useOrder = (Statuses?: OrderStatus[]): Order[] | null => {
  const socket = useSocket()

  // Have to set some start date to get any orders back.
  const orderParameters: OrderParameters = useMemo(
    () => getLastMonthOrderStreamParameters(Statuses),
    [Statuses],
  )

  const orders = useSubscription<Order>(socket, orderParameters)

  return orders
}

import React from 'react'
import { useRecoilState } from 'recoil'

import store from 'store'

import ColumnsEditor from './ColumnsEditor'

export interface OrdersColumnsEditorProps {
  'data-test': string
}

export const OrdersColumnsEditor: React.FC<OrdersColumnsEditorProps> = ({
  'data-test': dataTest = 'order-column-editor',
}) => {
  const [columns, setColumns] = useRecoilState(store.blotters.columns('orders'))

  return (
    <ColumnsEditor
      data-test={`${dataTest}.column-editor`}
      columns={columns}
      onChange={setColumns}
    />
  )
}

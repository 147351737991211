import styled from 'styled-components'
import tw from 'twin.macro'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const Icon = styled.svg`
  fill: none;
  width: 15px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${tw`
    border-0
    clip[rect(0 0 0 0)]
    clip-path[inset(50%)]
    h-[1px]
    m-[-1px]
    overflow-hidden
    p-0
    absolute
    whitespace-nowrap
    w-[1px]
  `}
`

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  ${tw`
      w-[24px]
      h-[24px]
      border-radius[4px]
      transition[all
      150ms]
      border-2
      border-solid
      border-light-600
      inline-flex
      justify-center
      items-center
      cursor-pointer
    `}

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
